/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
  font-family: 'SourceHanSans-Bold', sans-serif;
  overflow-x: hidden;
}

img {
  display: block;
}

* {
  touch-action: pan-y;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1 {
  font-family: "FZLanTingHeiS-DB1-GB";
}

.clearfix:after {

  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;

}

svg{
  // display: inline-block;
  // vertical-align: middle;
  fill: currentColor;
  width: 1em;
  height: 1em;
  overflow: hidden;
}

.video-mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(35, 37, 38, 1);
  display: none;
  z-index: 10000 !important;

  video {
    position: absolute;
    z-index: 101;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 1200px;
    height: 640px;
  }

  .video-close {
    background-color: rgba(0, 0, 0, 0.2);
    width: 48px;
    height: 48px;
    cursor: pointer;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-size: 16px auto;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 16px;
    right: 30px;
    background-image: url('../images/close.svg');

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}