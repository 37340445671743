.footer-box {
  width: 100%;
  // height: 450px;
  padding: 30px 300px 20px 300px;
  // border: 1px solid #797979;
  background-color: #333333;
  box-sizing: border-box;
  color: #797979;
  scroll-snap-align: end;
  font-size: 12px;

  .footer-list {
    display: flex;
    justify-content: space-between;

    .footer-item {
      a {
        color: inherit;
        font-size: 12px;

        &:hover {
          color: #fff;
        }
      }

      h3 {
        color: #fff;
        font-weight: unset;
        margin-bottom: 30px;
      }

      li {
        margin: 30px 0;
      }
    }
  }

  .footer-info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #797979;
    .footer-logo {
      width: 150px;
      height: 61px;
      margin-right: 100px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .footer-info-content {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .footer-info-right{
        // width: 330px;
      }

      .footer-info-left{
        flex: 1;
      }

      .footer-info-left,
      .footer-info-right {

        p {
          margin: 20px 0;
        }
      }


    }
  }

  .footer-copyright-box{
    margin-top: 10px;
    .footer-copyright{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: #797979;

      img{
        display: inline-block;
        width: 20px;
        height: 20px;
      }

      span{
        display: flex;
        align-items: center;
        a{
          display: flex;
          align-items: center;
          text-decoration: underline;
          color: inherit;


          &:hover{
            color: #fff;
          }
        }
      }
    }

    .footer-icon{
      display: flex;
      width: 200px;
      justify-content: space-around;
      font-size: 30px;

      div{
        width: 36px;
        height: 36px;
        // img{
        //   width: 100%;
        //   height: 100%;
        // }
      }

      .youtube{
        color: #fff;
        &:hover{
          color: #f0863d;
        }
      }

      .tiktok{
        color: #fff;

        &:hover{
          color: #4095E5;
        }
      }

      .wechat{
        color: #fff;
        position: relative;
        font-size: 29px;
        .qr-code{
          box-sizing: border-box;
          position: absolute;
          top: -110px;
          left: calc(-100% - 8px);
          width: 100px;
          height: 100px;
          background: #fff;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0,0,0,.2);
          padding: 5px;
          opacity: 0;
          display: none;

          img{
            width: 100%;
            height: 100%;
          }

          &::after{
            content: '';
            display: block;
            position: absolute;
            bottom: -4px;
            left: 50%;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #fff;
            transform: translateX(-50%);
          }
        }

        &:hover{
          color: #1afa29;

          .qr-code{
            opacity: 1;
            display: block;
          }
        }
      }
    }
  }
}