.header-box {
  position: sticky;
  // border: 1px solid #efefef;
  top: 0;
  z-index: 10000;
  background-color: #fff;
  .header {
    padding: 0 125px 0 100px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;

    .logo {
      display: flex;
      align-items: center;

      .quality-logo{
        width: 46px;
        height: 44px;
        margin-right: 30px;
      }
      .company-logo{
        width: 100px;
        height: 44px;
      }
    }

    .nav {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .nav-list {
        display: flex;

        li {
          width: 149px;
          height: 64px;
          line-height: 64px;
          text-align: center;
          font-weight: bold;
          font-size: 12px;
          // position: relative;

          cursor: pointer;
          &:hover a.title{
            color: #0090e7;
          }

          ul{
            a:hover{
              color: #aaaaaa;
            }
          }
        }

        .products-new {
          background-color: #fff;

          &:hover .product-nav {
            height: 337px;
          }

          &:hover .product-nav .child-nav {
            top: 0;
          }

          .product-nav {
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            top: 64px;
            overflow: hidden;
            transition: all 0.5s;
            z-index: -1;

            .child-nav {
              position: absolute;
              top: -335px;
              left: 0;
              width: 100%;
              height: 335px;
              background-color: #fff;
              display: flex;
              border: 1px solid #efefef;
              border-radius: 0 0 35px 35px;
              transition: all 0.5s;



              .image {
                width: 200px;
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                  width: 100%;
                  height: auto;
                }
              }

              .type-box {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                text-align: left;
                width: 33.33%;

                .info-box {
                  height: 250px;
                  font-size: 15px;
                }


                &~.type-box {
                  position: relative;

                  &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    content: '';
                    display: block;
                    width: 2px;
                    height: 60%;
                    background-color: #efefef;
                  }
                }

                p {
                  height: 30px;
                  margin-bottom: 20px;
                  line-height: 30px;
                }

                ul {
                  // display: flex;
                  font-weight: initial;

                  li {
                    font-weight: initial;
                    text-align: left;
                    height: 50px;
                    line-height: 50px;

                    color: initial;

                    
                  }

                }
              }

              .exhibit-list{
                ul {
                  display: flex;
                  flex-wrap: wrap;
                  width: 200px;

                  li {
                    width: 100px;
                  }

                }
              }


              .accessories {
                ul {
                  display: flex;
                  flex-wrap: wrap;
                  width: 200px;

                  li {
                    width: 100px;
                  }

                }
              }
            }


          }
        }

        .industry-new {
          background-color: #fff;

          &:hover .product-nav {
            height: 337px;
          }

          &:hover .product-nav .child-nav {
            top: 0;
          }

          .product-nav {
            position: absolute;
            width: calc(100% - 425px);
            height: 0;
            right: 0;
            top: 64px;
            overflow: hidden;
            transition: all 0.5s;
            z-index: -1;

            .child-nav {
              position: absolute;
              top: -335px;
              left: 0;
              width: 100%;
              height: 335px;
              background-color: #fff;
              display: flex;
              border: 1px solid #efefef;
              border-radius: 0 0 35px 35px;
              transition: all 0.5s;



              .image {
                width: 200px;
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                  width: 100%;
                  height: auto;
                }
              }

              .type-box {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                text-align: left;
                width: 33.33%;

                .info-box {
                  height: 250px;
                  font-size: 15px;
                }


                &~.type-box {
                  position: relative;

                  &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    content: '';
                    display: block;
                    width: 2px;
                    height: 60%;
                    background-color: #efefef;
                  }
                }

                p {
                  height: 30px;
                  margin-bottom: 20px;
                  line-height: 30px;
                }

                ul {
                  // display: flex;
                  font-weight: initial;

                  li {
                    font-weight: initial;
                    text-align: left;
                    height: 50px;
                    line-height: 50px;

                    color: initial;

                    
                  }

                }
              }
            }


          }
        }


        .service-new {
          position: relative;
          *{
            box-sizing: border-box;
          }

          .service-box {
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            top: 64px;
            overflow: hidden;
            transition: all 0.5s;
            z-index: -1;
            border-radius: 0 0 25px 25px;
          }

          .service-nav {
            position: absolute;
            left: 0;
            top: -250px;
            width: 100%;
            height: 250px;
            transition: all 0.5s;
            border: 1px solid #efefef;
            border-radius: 0 0 25px 25px;
            background-color: #fff;

            li {
              height: 50px;
              line-height: 50px;
              font-weight: initial;
            }
          }

          &:hover .service-box {
            height: 250px;
          }

          &:hover .service-nav {
            top: 0;
          }
        }



        .about-us-new {
          position: relative;
          * {
            box-sizing: border-box;
          }

          .about-us-box {
            box-sizing: border-box;
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            top:64px;
            overflow: hidden;
            transition: all 0.5s;
           
            z-index: -1;
          }

          .about-us-nav {
            box-sizing: border-box;
            position: absolute;
            left: 0;
            top: -100px;
            width: 100%;
            height: 100px;
            transition: all 0.5s;
            border-radius: 0 0 25px 25px;
            border: 1px solid #efefef;  
            background-color: #fff;

            li {
              height: 50px;
              line-height: 50px;
              font-weight: initial;
            }
          }

          &:hover .about-us-box {
            height: 100px;
          }

          &:hover .about-us-nav {
            top: 0;
          }
        }
      }
    }
  }


  a {
    text-decoration: none;
    color: inherit;
  }

  .change-languages {
    cursor: pointer;
    font-weight: 800;
    font-size: 12px;
  }
  .lang-box{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,0.7);
    z-index: 1000;
    display: none;

    .lang-box-content{
      width: 50%;
      height: 250px;
      padding: 20px;
      background-color: #fff;
      border-radius: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 200px auto;
      border: 1px solid #efefef;
      position: relative;

      .closure{
        position: absolute;
        right: 30px;
        top: 20px;
        width: 50px;
        height: 50px;
        background-color: #fff;
        // border: 1px solid #efefef;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-image: url('../images/close.svg');  
        background-size: 16px auto;
        background-repeat: no-repeat;
        background-position: center;
      }


      .lang-box-title{
        width: 60%;
        border-bottom: 2px solid #efefef;
        margin: 0 auto;
        padding-bottom: 10px;
        font-size: 24px;
        text-align: center;
        line-height: 50px;
      }


      .lang-box-content-item{
        flex: 1;
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        
        button{
          width: 180px;
          height: 70px;
          outline: none;
          border: none;
          background-color: rgba(242, 242, 242, 0.7);
          font-size: 16px;
          color: #000;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }

  }

}